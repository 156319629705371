<template>
  <administration-layout>
    <div class="flex flex-col items-center py-4">
      <div class="w-full xl:px-36 sm:px-8">
        <div class="flex flex-row items-center py-4">
          <h1 class="text-lg font-bold inline-flex items-center px-3 py-3">
            <strong>Mi Membresia</strong>
          </h1>
        </div>
        <div class="flex flex-col">
          <content-card slotCustomClass="flex flex-col lg:flex-row" class="mb-4">
            <div class="w-full lg:w-1/3 lg:mr-2">
              <strong>Tu plan:</strong>
              <div class="w-full">
                <el-button size="sm" class="w-full" :class="existsPayment ? 'el-button--primary' : ''"
                  @click="viewPayment">
                  {{ existsPayment ? membershipTypes[$store.state.account.membershipType] : 'Contrata tu plan' }}
                </el-button>
                <div class="pl-1">
                  <a class="text-purple-400 text-sm" @click="confirmUnsubscribe">Cancelar mi subscripcion?</a>
                </div>
              </div>
            </div>
            <div class="w-full lg:w-1/3 lg:ml-2 lg:mr-2">
              <strong>Vence el dia:</strong>
              <div class="w-full">
                <el-date-picker v-model="$store.state.account.membershipEndDate" type="date" disabled
                  :format="$store.state.business.dateFormat" class="w-full bg-select-button">
                </el-date-picker>
              </div>
            </div>
            <div class="w-full lg:w-1/3 lg:ml-2" v-if="existsPayment">
              <strong>Ultimo pago:</strong>
              <div class="w-full flex flex-row">
                <el-date-picker v-model="$store.state.account.membershipLastPay.createdAt" type="date" disabled
                  :format="$store.state.business.dateFormat" class="w-full bg-select-button">
                </el-date-picker>
              </div>
            </div>
          </content-card>
          <content-card v-if="existsPayment">
            <div class="flex flex-row items-center">
              <span class="text-lg font-bold">Detalles de tu ultimo Pago</span>
            </div>
            <div class="block w-full overflow-x-auto">
              <table class="items-center w-full border-collapse">
                <thead>
                  <tr class="px-2 border-b-2">
                    <th class="py-3 font-bold text-left border-l-0 border-r-0 whitespace-nowrap">
                      Fecha
                    </th>
                    <th class="py-3 font-bold text-left border-l-0 border-r-0 whitespace-nowrap">
                      Tipo
                    </th>
                    <th class="py-3 font-bold text-left border-l-0 border-r-0 whitespace-nowrap">
                      Monto
                    </th>
                    <th class="py-3 font-bold text-left border-l-0 border-r-0 whitespace-nowrap">
                      Tipo de Moneda
                    </th>
                    <th class="py-3 font-bold text-left border-l-0 border-r-0 whitespace-nowrap">
                      Estatus
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="cursor-pointer hover:bg-gray-100 hover:shadow">
                    <td class="p-2 border-t" style="min-width: 125px">
                      {{ lastPayDate }}
                    </td>
                    <td class="p-2 border-t" style="min-width: 125px">
                      {{ lastPayType }}
                    </td>
                    <td class="p-2 border-t" style="min-width: 125px">
                      {{ moneyFormat(lastPayAmount) }}
                    </td>
                    <td class="p-2 border-t" style="min-width: 125px">
                      {{ lastPayCurrency }}
                    </td>
                    <td class="p-2 border-t" style="min-width: 125px">
                      <div class="text-center rounded-lg text-white"
                        :class="$store.state.account.membershipLastPay.succeeded ? 'success' : 'failed'">
                        {{ lastPayStatus }}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </content-card>
        </div>
      </div>
    </div>
  </administration-layout>
</template>
<style scoped>
.success {
  background-color: forestgreen;
}

.failed {
  background-color: firebrick;
}
</style>
<script>
import _ from "lodash";
import error from '@/mixins/error';
import numbers from '@/utils/numbers';
import { MembershipEnum, AccountTypeEnum } from '@/constants';
import PaymentService from '@/services/PaymentService';
import PaymentDialogService from '@/components/dialogs/Payment/service';

export default {
  name: 'Membership',
  mixins: [error],
  components: {
    AdministrationLayout: () => import('@/components/organisms/TheAdministrationApp'),
    ContentCard: () => import('@/components/molecules/ContentCard'),
  },
  data() {
    return {
      errorMessage: null,
      visibleLastPaymentDetails: false,
      membershipTypes: {
        [MembershipEnum.Basic]: 'Básico',
        [MembershipEnum.Professional]: 'Profesional',
        [MembershipEnum.Premium]: 'Premium',
      },
    }
  },
  watch: {
    'errorMessage': {
      deep: true,
      handler: _.debounce(async function () {
        if (this.errorMessage) {
          await this.$toastr.e(this.errorMessage);
          this.errorMessage = undefined;
        }
      }, 300)
    },
  },
  computed: {
    existsPayment() {
      return this.$store.state.account.membershipLastPay.createdAt;
    },
    lastPayDate() {
      const format = this.$store.state.business.dateFormat?.toUpperCase() ?? "MM/DD/YYYY";
      return this.$moment(new Date(this.$store.state.account.membershipLastPay.createdAt)).format(format);
    },
    lastPayType() {
      return {
        payment_intent: 'Pago'
      }[this.$store.state.account.membershipLastPay.object] || 'Pago';
    },
    lastPayAmount() {
      return this.$store.state.account.membershipLastPay.amount / 100;
    },
    lastPayCurrency() {
      return this.$store.state.account.membershipLastPay.currency.toUpperCase();
    },
    lastPayStatus() {
      return this.$store.state.account.membershipLastPay.succeeded ? 'Exitoso' : 'Fallido';
    },
  },
  methods: {
    viewPayment() {
      if (this.$store.state.account.accountType !== AccountTypeEnum.Owner) {
        this.$swal.fire({
          title: 'Acceso Denegado.',
          icon: 'error',
          text: 'Los pagos se deben realizar desde la cuenta madre (Dueño) con la que se inicio en samii.',
        });
        return;
      }
      PaymentDialogService.show();
    },
    moneyFormat(value) {
      const { currencySymbol } = this.$store.state.account;
      return numbers.moneyFormat(value, currencySymbol);
    },
    confirmUnsubscribe() {
      this.$confirm(
        `¿Está seguro que deseas cancelar tu subscripcion?`,
        {
          type: "warning",
          confirmButtonText: "Si",
          cancelButtonText: "No",
        }
      ).then(async () => {
        await this.unsubscribe();
      });
    },
    async unsubscribe() {
      try {
        await PaymentService.unsubscribe();
        this.$toastr.s('Has cancelado tu subscripcion');
      } catch (e) {
        this.errorMessage = this.getErrorMessage(e);
      }
    }
  },
}
</script>